import React from "react";
import to from "await-to-js";
import ApiService from "./../services/Api";
import SiteService from "./../services/Site";
import AuthService from "./../services/Auth";
import UserService from "./../services/User";
import loadable from "loadable-components";
import dayjs from "dayjs";
import SiteConstants from "../constants/Site";
import CollectiveSitesService from "../services/CollectiveSites";
import EditorContainer from "../components/Site/EditorContainer";
import Database from "../constants/Database";
import { Columns } from "react-bulma-components/full";
import { withRouter } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import { Translation } from "react-i18next";
import { createRegExpFromSearchWords } from "../utils/Utils";
import AuthConstants from "../constants/Auth";
import Axios from "axios";
import { checkWidth } from "../utils/Responsive";
import { extractEnedisErrorCode, formatErrorStatus } from "../utils/Utils";
import "./Home.scss";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationPopUp from "../components/Admin/ConfirmationPopUp";
import PLANS from "../constants/Plans";
import { CustomModal } from "../components/CustomModal";
import { CustomGetACCMessage } from "../components/CustomCTAMessage";
import { asideMenuItems } from "../constants/LateralMenu";
import { resetSiteData } from "../store/site/siteStore";
import { getUserData } from "../store/authentication/AuthenticationStore";
import { getDefaultRate } from "../services/DefaultRates";
// import { isSiteIsDisabled } from "../helpers/Validators";
import Consent from "../services/Consent";
import _ from "lodash";
const ListOfSites = loadable(() => import("../components/Home/ListOfSites"));

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rendering: "",
      sites: [],
      siteToEdit: null,
      isLoadingUserSites: false,
      showDeleteSiteModal: false,
      allowedNumberOfSites: null,
      isProducer: false,
      optimNumber: null,
      isSearching: false,
      wordToSearch: "",
      wordToSearchDate: "",
      wordToSearchDateModification: "",
      userType: "",
      userLicense: "",
      userDefaultRates: [],
      isAsideMenuActive: false,
      isMobile: checkWidth(),
      showEventPopUp: false,
      showConfirmationPopUp: false,
      siteToDelete: "",
      showGetACCCTA: false,
      filtredSites: [],
    };
  }
  static contextType = LocaleContext;

  setLoadingUserSites  = (isLoadingUserSites) => {
    this.setState({ isLoadingUserSites });
  }

  createUserSiteTag = async (sites) => {
    if (AuthService.getUserId()) {
      const siteTag = {};
      const { CONSUMER, PRODUCER, CONSUMER_PLUS } = SiteConstants.type;

      for (const site of sites) {
        if (
          siteTag.hasConsumerTag &&
          siteTag.hasProducerTag &&
          siteTag.hasConsumerPlusTag
        )
          break;

        if (site.type === CONSUMER) {
          siteTag.hasConsumerTag = true;
          continue;
        }
        if (site.type === PRODUCER) {
          siteTag.hasProducerTag = true;
          continue;
        }
        if (site.type === CONSUMER_PLUS) {
          siteTag.hasConsumerPlusTag = true;
          continue;
        }
      }

      UserService.update(AuthService.getUserId(), { siteTag });
    }
  };

  addConsentKeys = async (sites) => {
    sites.map(async (el) => {
      if (
        el.enedisData &&
        !el.hasOwnProperty("hasConsentData") &&
        !el.consentFileUrl &&
        !el.isC_AND_I
      ) {
        await SiteService.updateKey(el.id, "hasConsentData", false);
      } else if (
        el.enedisData &&
        !el.hasOwnProperty("hasConsentData") &&
        el.consentFileUrl &&
        !el.isC_AND_I
      ) {
        await SiteService.updateKey(el.id, "hasConsentData", true);
      } else {
        console.log("NO NEED CONSENT KEYS");
      }

      if (!el.hasOwnProperty("disabled")) {
        await SiteService.updateKey(el.id, "disabled", false);
      }
      if (
        el.enedisData &&
        !el.hasOwnProperty("limitConsentDate") &&
        !el.hasConsentData &&
        !el.isC_AND_I
      ) {
        const today = dayjs();
        const limitDate = today.add(7, "day").format("DD/MM/YYYY");
        await SiteService.updateKey(el.id, "limitConsentDate", limitDate);
      }
    });
  };

  checkConsent = async (sites) => {
    const dateOfToday = dayjs();
    sites.map(async (el) => {
      const dateLimit = dayjs(el.limitConsentDate, "DD/MM/YYYY");
      const consent = await Consent.getSiteConsent(el.id);

      if (consent.length > 0) {
        await SiteService.updateKey(el.id, "hasConsentData", true);
        await SiteService.updateKey(el.id, "disabled", false);
      }
      if (
        el.enedisData &&
        !el.hasConsentData &&
        dateLimit.isBefore(dateOfToday) &&
        !el.isC_AND_I
      ) {
        await SiteService.updateKey(el.id, "disabled", true);
      } else {
        await SiteService.updateKey(el.id, "disabled", false);
      }
    });
  };

  fetchUserSites = async (withAnimation = true) => {
    if (withAnimation) this.setState({ isLoadingUserSites: withAnimation });

    const userId = AuthService.getUserId();
    resetSiteData();
    // fetch user type
    // then if CONSUMER_PLUS fetch collectiveSites and sites
    // or only sites if not
    const [err, sites] = await to(
      this.fetchUserType().then((userType) =>
        [
          SiteConstants.type.CONSUMER,
          SiteConstants.type.CONSUMER_PLUS,
          SiteConstants.type.PRODUCER,
        ].includes(userType)
          ? Promise.all([
              CollectiveSitesService.getUserSites(userId),
              SiteService.getUserSites(userId),
            ]).then(([collectiveSites, sites]) => [
              ...collectiveSites,
              ...sites,
            ])
          : SiteService.getUserSites(userId)
      )
    );

    if (err)
      return console.error(`Cannot fetch user sites. Error : ${err.message}`);
    await this.addConsentKeys(sites);
    await this.checkConsent(sites);
    this.setState({
      filtredSites: sites,
      sites,
      wordToSearch: "",
      wordToSearchDate: "",
      wordToSearchDateModification: "",
    });

    if (withAnimation) this.setState({ isLoadingUserSites: false });

    this.createUserSiteTag(sites);
  };

  fetchNumberOfSites = async () => {
    const [err, numberOfAllowedSites] = await to(
      UserService.getNumberOfAllowedSites(AuthService.getUserId())
    );

    if (err)
      return console.error(
        `Cannot fetch user number of Allowed sites. Error : ${err.message}`
      );

    this.setState(numberOfAllowedSites);
  };

  canCreateSite = () => {
    let {
      sites: { length },
      allowedNumberOfSites,
    } = this.state;

    return length < allowedNumberOfSites;
  };

  handleClickAsideMenu = (menuItem) => {
    switch (menuItem) {
      case asideMenuItems.SITES:
        this.fetchNumberOfSites();
        this.fetchUserSites();

        break;
      default:
        break;
    }
    this.setState({ rendering: menuItem });
  };

  handleShowCreateSite = () => {
    this.setState({
      rendering: asideMenuItems.CREATE_SITE,
    });
  };

  showGetACCCTA = () => this.setState({ showGetACCCTA: true });

  handleShowEditSite = (siteToEdit) => {
    if (siteToEdit.type == SiteConstants.type.CONSUMER_PLUS) {
      const sitesIds = siteToEdit.sites.map((site) => site.id);
      // sites of this collective site variable contains the sub-sites ids only and not the whole sub-sites
      const noneMergedCollectiveSite = { ...siteToEdit, sites: sitesIds };
      console.log({ siteToEdit });
      return this.setState({
        rendering: asideMenuItems.EDIT_SITE,
        siteToEdit: noneMergedCollectiveSite,
      });
    }
    this.setState({ rendering: asideMenuItems.EDIT_SITE, siteToEdit });
  };

  handleSubmitSuccessSite = async (site) => {
    await this.fetchUserSites();

    // checkAllSitesIfReportURL is triggred on each sites change
    this.setState({ rendering: asideMenuItems.SITES });
  };

  handleShowModalRemoveSite = (siteToDelete) =>
    this.setState({ siteToDelete, showDeleteSiteModal: true });

  handleHideModalRemoveSite = () =>
    this.setState({ siteToDelete: null, showDeleteSiteModal: false });

  handleRemoveSite = async () => {
    const siteId = this.state.siteToDelete.id;

    this.setState({
      isLoadingUserSites: true,
      showDeleteSiteModal: false,
      siteToDelete: null,
    });
    const [err] = await to(SiteService.remove(siteId));
    if (err) return console.error(`Cannot remove site. Error : ${err.message}`);
    this.fetchUserSites();
  };

  updateNumberOfSite = async (allowedNumberOfSites) => {
    let err = null;

    [err] = await to(
      UserService.updateNumberOfSite(
        AuthService.getUserId(),
        allowedNumberOfSites
      )
    );
    if (err) console.log("err :", err);
  };

  handleGoSearch = (
    wordToSearch,
    wordToSearchDateModification,
    wordToSearchDate
  ) => {
    this.setState({
      wordToSearch,
      wordToSearchDateModification,
      wordToSearchDate,
    });
    if (
      wordToSearch === "" &&
      wordToSearchDateModification === "" &&
      wordToSearchDate === ""
    ) {
      this.setState({
        filtredSites: [...this.state.sites],
      });
    } else {
      this.handleSearchSite(
        wordToSearch,
        wordToSearchDateModification,
        wordToSearchDate
      );
    }
  };

  handleSearchSite = (searchPhrase, editDate, createdAt) => {
    this.setState({ isSearching: true });

    const filtredSites = [];
    const regExp = createRegExpFromSearchWords(searchPhrase);
    const criterias = ["name", "addressCity", "addressZipCode"];
    createdAt = createdAt.replace(/\//g, "-");
    editDate = editDate.replace(/\//g, "-");

    this.state.sites.forEach((site) => {
      for (const criteria of criterias) {
        let valueToTest = "";

        try {
          //remove all accents/diacritics (é becomes e, à becomes a ...)
          valueToTest = site[criteria]
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        } catch (err) {
          console.error(err);
        }

        if (
          (editDate !== "" &&
            site._lastModified &&
            site._lastModified
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-")
              .includes(editDate) === false) ||
          (editDate !== "" && !site._lastModified)
        )
          return;
        if (
          (createdAt !== "" &&
            site._createdAt
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-")
              .includes(createdAt) === false) ||
          (createdAt !== "" && !site._createdAt)
        )
          return;
        if (regExp.test(valueToTest)) {
          filtredSites.push(site);
          return;
        }
      }

      // check if any of the collectiveSites' subsite has a name that match the regEx
      // the adress is shared so no need to test it
      if (site.sites) {
        const someSubSitesMatch = site.sites.some((site) => {
          let valueToTest = "";
          try {
            //remove all accents/diacritics (é becomes e, à becomes a ...)
            valueToTest = site.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          } catch (err) {
            console.error(err);
          }

          if (
            (editDate !== "" &&
              !site._lastModified &&
              site._lastModified.split("T")[0].includes(editDate) === false) ||
            (editDate !== "" && !site._lastModified)
          )
            return;

          if (
            (createdAt !== "" &&
              site?._createdAt.split("T")[0].includes(createdAt) === false) ||
            (createdAt !== "" && !site._createdAt)
          )
            return;
          return regExp.test(valueToTest);
        });
        if (someSubSitesMatch) {
          filtredSites.push(site);
        }
      }
    });

    return this.setState({
      filtredSites,
      isSearching: false,
    });
  };

  handleDeleteSite = async (siteId) => {
    const { sites } = this.state;
    const site = sites.find((site) => site.id === siteId);

    // users with ESSENTIAL license are not allowed to delete sites
    if (
      this.state.userLicense === PLANS().DEMO.toUpperCase()
      // ||isSiteIsDisabled(site)
    ) {
      return;
    }

    this.setState({ showConfirmationPopUp: true });

    return this.setState({
      isLoadingUserSites: false,
      // sites: sites.filter((site) => site.id !== siteId),
      siteToDelete: site,
    });
  };

  handleSiteUpdate = async (site) => {
    const { id, collectiveSiteId } = site;
    let sites = [];
    const now = dayjs().unix();
    // update site in state to have no reportURL
    if (collectiveSiteId) {
      sites = this.state.sites.map((s) =>
        s.id === collectiveSiteId
          ? {
              ...s,
              sites: s.sites.map((subSite) => {
                SiteService.deleteAnalysisFiles(
                  subSite.id,
                  subSite.collectiveSiteId
                );

                return subSite.id === id
                  ? {
                      ...subSite,
                      reportURL: "",
                      updateStatus: true,
                      automaticUpdateError: null,
                      lastFetched: now,
                    }
                  : subSite;
              }),
            }
          : s
      );
    } else {
      await SiteService.deleteAnalysisFiles(site.id);
      sites = this.state.sites.map((s) =>
        s.id === id
          ? {
              ...s,
              reportURL: "",
              updateStatus: true,
              automaticUpdateError: null,
              lastFetched: now,
            }
          : s
      );
    }

    // update the Site in Firebase to have no reportURL
    await SiteService.update(id, {
      automaticUpdateError: null,
      reportURL: "",
      lastFetched: now,
    });

    // force the re-generation of a reportURL
    try {
      ApiService.forceUpdateData(id);
    } catch (e) {
      console.log("Erreur update data => ", e);
    }
    this.setState({
      sites,
      filtredSites: sites,
    });

    // There was an update in the state
    // on each update of the state, checkAllSitesIfReportURL is triggred by ListOfSites
    // So the check for this site's reportURL will be triggerd too
  };

  handleUpdate = async (site) => {
    // if collectiveSite, sitesToUpdate are all subSites which dont have a reportURL
    // if individualSite, sitesToUpdate are the individualSite on its own
    let sitesToUpdate = site.sites || [site];

    sitesToUpdate = sitesToUpdate.filter((site) => {
      if (_.has(site, "lastFetched") && site.lastFetched !== null) {
        let fetched = dayjs.unix(site.lastFetched);
        let now = dayjs();
        let diff = now.diff(fetched, "minute");
        // if site has been updated less than 10 minutes ago, return false to not update it
        if (diff < 5) return false;
      }
      if ([SiteConstants.DATA_SOURCES.ENEDIS].includes(site.dataSource)) {
        return (
          site.reportURL === "" &&
          site.automaticUpdateError !== null &&
          site.automaticUpdateError !== "Report Retrieved"
        );
      } else {
        return (
          site.automaticUpdateError !== null &&
          site.automaticUpdateError !== "Report Retrieved"
        );
      }
    });

    sitesToUpdate.forEach((site) => this.handleSiteUpdate(site));
  };

  siteIsComplete = (site) => {
    if (site.type === SiteConstants.type.CONSUMER)
      return !!site.purchaseAreaOfTension && !!site.address;
    else if (site.type === SiteConstants.type.CONSUMER_PLUS)
      return (
        !!site.address &&
        site.sites.length > 0 &&
        site.sites.every((site) => !!site.purchaseAreaOfTension)
      );
    else return !!site.grid_params;
  };

  updateCollectiveSiteListOfSites = (collectiveSiteId, subSite) =>
    this.setState({
      sites: this.state.sites.map((site) =>
        site.id === collectiveSiteId
          ? {
              ...site,
              sites: site.sites.filter((sub) => sub.id !== subSite.id),
            }
          : site
      ),
    });

  renderListOfSites = () => {
    let {
      sites = [],
      filtredSites,
      isLoadingUserSites,
      allowedNumberOfSites,
      optimNumber,
      wordToSearch,
      wordToSearchDate,
      wordToSearchDateModification,
      isSearching,
      mailSent,
    } = this.state;

    // filtredSites = filtredSites || sites;

    const incompleteSites = filtredSites.filter(
      (site) => !this.siteIsComplete(site)
    );

    filtredSites = filtredSites.filter((site) => this.siteIsComplete(site));

    return (
      <ListOfSites
        filtredSites={filtredSites}
        sites={this.state.sites}
        isLoadingUserSites={isLoadingUserSites}
        setLoadingUserSites={this.setLoadingUserSites}
        canCreateSite={this.canCreateSite()}
        allowedNumberOfSites={allowedNumberOfSites}
        handleShowCreateSite={this.handleShowCreateSite}
        handleShowEditSite={this.handleShowEditSite}
        optimNumber={optimNumber}
        handleGoSearch={this.handleGoSearch}
        wordToSearch={wordToSearch}
        wordToSearchDate={wordToSearchDate}
        wordToSearchDateModification={wordToSearchDateModification}
        isSearching={isSearching}
        handleIsSearching={this.handleIsSearching}
        Translation={Translation}
        handleDeleteSite={this.handleDeleteSite}
        handleUpdate={this.handleUpdate}
        checkIfReportURL={this.checkAllSitesIfReportURL}
        handleDemo={this.handlePurchase}
        mailSent={mailSent}
        fetchUserSites={this.fetchUserSites}
        addConsentKeys={this.addConsentKeys}
        checkConsent={this.checkConsent}
        history={this.props.history}
        incompleteSites={incompleteSites}
        updateCollectiveSiteListOfSites={this.updateCollectiveSiteListOfSites}
        userType={this.state.userType}
        showGetACCCTA={this.showGetACCCTA}
      />
    );
  };

  cancelEditor = async (site = {}) => {
    if (
      site &&
      site.type === SiteConstants.type.CONSUMER &&
      !site.isFullSellOut &&
      site.reportURL === "" &&
      !site._step3
    ) {
      const [err] = await to(SiteService.remove(site.id));
      if (err)
        return console.error(`Cannot remove site. Error : ${err.message}`);
      this.fetchUserSites();
    }

    this.setState({ rendering: asideMenuItems.SITES, siteToEdit: null });
  };

  renderCreateSite = () => (
    <EditorContainer
      userType={this.state.userType}
      onSubmitSuccess={this.handleSubmitSuccessSite}
      userDefaultRates={this.state.userDefaultRates}
      cancelEditor={this.cancelEditor}
    />
  );
  renderEditSite = () => (
    <EditorContainer
      userType={this.state.userType}
      type={this.state.userType}
      onSubmitSuccess={this.handleSubmitSuccessSite}
      userDefaultRates={this.state.userDefaultRates}
      editMode={true}
      siteToEdit={this.state.siteToEdit}
      cancelEditor={this.cancelEditor}
    />
  );
  renderBody = () => {
    const { rendering } = this.state;
    switch (rendering) {
      case asideMenuItems.SITES:
        return this.renderListOfSites();
      case asideMenuItems.CREATE_SITE:
        return this.renderCreateSite();
      case asideMenuItems.EDIT_SITE:
        return this.renderEditSite();
      default:
        return <div></div>;
    }
  };

  renderConfirmationPopUp = () => {
    const { i18n } = this.context;
    const { site } = this.state;
    if (!this.state.showConfirmationPopUp) return null;

    const onClose = () =>
      this.setState({ showConfirmationPopUp: false, siteToDelete: "" });
    const onConfirm = async (site) => {
      if (this.state.siteToDelete.type === SiteConstants.type.CONSUMER_PLUS) {
        await CollectiveSitesService.transferToUser(
          this.state.siteToDelete.id,
          Database.TRASH_USER_ID
        );
      } else {
        await SiteService.transferToUser(
          this.state.siteToDelete.id,
          Database.TRASH_USER_ID
        );
      }
      await this.fetchUserSites();
      onClose();
    };

    return (
      <ConfirmationPopUp
        style={{ zIndex: 999999 }}
        show={this.state.showConfirmationPopUp}
        onClose={onClose}
        onConfirm={onConfirm}
        action={i18n.t("delete").toLowerCase()}
        message={"Attention le site sera supprimé définitivement"}
        target={this.state.siteToDelete}
      />
    );
  };

  renderGetACCModal = () => {
    return (
      <CustomModal
        show={this.state.showGetACCCTA}
        title="Autoconsommation collective"
        onClose={() => this.setState({ showGetACCCTA: false })}
        onConfirm={() => this.setState({ showGetACCCTA: false })}
      >
        <CustomGetACCMessage />
      </CustomModal>
    );
  };

  render() {
    if (AuthService.isAuth()) {
      if (!AuthService.getUser().emailVerified) {
        UserService.updateVerified(AuthService.getUser().uid);
      }
    }

    return (
      <div className="container__Columns">
        <Columns className="is-fullheight" style={{ padding: "1em" }}>
          {/* <Columns.Columnf
            id={`side_navbar`}
            className={`has-background-blue has-no-padding-horizontal${toggleClassName(
              isMobile,
              "isMobile"
            )}${toggleClassName(isAsideMenuActive, "active")}`}
            size={2}
          >
            <span
              className="icon trigger"
              onClick={() => this.setState({ isAsideMenuActive: !isAsideMenuActive })}
            >
              <i className="fa fa-angle-right" />
            </span>
            <div className="has-background-white aside-menu-wrapper">
              <AsideMenu
                onClick={this.handleClickAsideMenu}
                current={rendering}
                asideMenuItems={asideMenuItems}
              />
            </div>
          </Columns.Column> */}
          <Columns.Column size={12} className="body-container home editor-site">
            {this.renderGetACCModal()}
            {this.renderConfirmationPopUp()}
            {this.renderBody()}
          </Columns.Column>
        </Columns>
      </div>
    );
  }

  countReports = () => {
    const { sites } = this.state;
    return sites.filter((s) => s.reportURL !== "").length;
  };

  checkIfReportURL = async (site, counter = 60) => {
    if (counter-- === 0) {
      SiteService.update(site.id, {
        automaticUpdateError:
          "Une erreur est survenue, merci de modifier la configuration de votre site ou contacter le support",
        updateStatus: false,
      });

      // update the site in the state
      let sites = [];
      if (site.collectiveSiteId) {
        sites = this.state.sites.map((stateSite) =>
          stateSite.id === site.collectiveSiteId
            ? {
                ...stateSite,
                sites: stateSite.sites.map((subSite) =>
                  subSite.id === site.id
                    ? {
                        ...site,
                        automaticUpdateError:
                          "Une erreur est survenue, merci de modifier la configuration de votre site ou contacter le support",
                        updateStatus: false,
                      }
                    : subSite
                ),
              }
            : stateSite
        );
      } else {
        sites = this.state.sites.map((stateSite) =>
          stateSite.id === site.id
            ? {
                ...site,
                automaticUpdateError:
                  "Une erreur est survenue, merci de modifier la configuration de votre site ou contacter le support",
                updateStatus: false,
              }
            : stateSite
        );
      }
      return this.setState({
        sites,
        filtredSites: sites,
      });
    }

    // if site already have a reportURL do nothing
    if (site.reportURL || site.automaticUpdateError) return;

    // fetch site from DB to check changes
    if (!AuthService.isAuth()) return;
    let [err, firebaseSite] = await to(SiteService.getById(site.id));

    if (err) {
      if (err.code === "unavailable") {
        console.log("Internet disconnected !");
        return this.checkIfReportURL(firebaseSite, counter);
      }
      return console.log("Couldn't fetch site from DB");
    }

    // if api throws an error update state
    if (
      firebaseSite.automaticUpdateError !== null &&
      firebaseSite.automaticUpdateError !== "Report Retrieved"
    ) {
      let error = firebaseSite.automaticUpdateError;

      // update the site in the state
      let sites = [];
      if (site.collectiveSiteId) {
        sites = this.state.sites.map((stateSite) =>
          stateSite.id === site.collectiveSiteId
            ? {
                ...stateSite,
                sites: stateSite.sites.map((subSite) =>
                  subSite.id === site.id
                    ? {
                        ...firebaseSite,
                        automaticUpdateError: formatErrorStatus(
                          extractEnedisErrorCode(error)
                        ),
                      }
                    : subSite
                ),
              }
            : stateSite
        );
      } else {
        sites = this.state.sites.map((stateSite) => {
          return stateSite.id === site.id
            ? {
                ...firebaseSite,
                automaticUpdateError: formatErrorStatus(
                  extractEnedisErrorCode(error)
                ),
              }
            : stateSite;
        });
      }
      return this.setState({
        sites,
        filtredSites: sites,
      });
    }

    // if api generated a reportURL update state
    if (
      (firebaseSite.reportURL &&
        firebaseSite.automaticUpdateError === "Report Retrieved") ||
      (firebaseSite.dataSource === "PROFILE" && firebaseSite.reportURL)
    ) {
      // update the site in the state
      let sites = [];
      if (site.collectiveSiteId) {
        sites = this.state.sites.map((stateSite) => {
          return stateSite.id === site.collectiveSiteId
            ? {
                ...stateSite,
                sites: stateSite.sites.map((subSite) =>
                  subSite.id === site.id
                    ? {
                        ...firebaseSite,
                      }
                    : subSite
                ),
              }
            : stateSite;
        });
      } else {
        sites = this.state.sites.map((stateSite) =>
          stateSite.id === site.id
            ? {
                ...firebaseSite,
              }
            : stateSite
        );
      }
      return this.setState({
        sites,
        filtredSites: sites,
      });
    }
    // enter the loop if there's no error and no report and site configured
    if (
      (firebaseSite.reportURL === "" &&
        firebaseSite.enedisNumber &&
        firebaseSite.automaticUpdateError === null &&
        firebaseSite.purchaseAreaOfTension) ||
      (firebaseSite.dataSource === "PROFILE" && firebaseSite.reportURL === "")
    ) {
      setTimeout(() => {
        this.checkIfReportURL(firebaseSite, counter);
      }, 2000); // recheck data base for changes after 5s
    }
  };

  checkAllSitesIfReportURL = () => {
    const { sites } = this.state;

    // if no sites or if all sites have a report, return
    if (sites.length === 0) return;

    sites.forEach((site) => {
      const sitesToCheck = site.sites || [site];
      sitesToCheck.forEach(async (site) => {
        return this.checkIfReportURL(await site, 200);
      }); // 60 possible attempt of 5s => 3min
    });
  };

  fetchUserType = async () => {
    let user = null;
    [, user] = await to(UserService.get(AuthService.getUserId()));
    this.setState({
      userType: user.type,
      userLicense: user.license,
      addressCity: user.addressCity,
      addressZipCode: user.addressZipCode,
      uid: user.uid,
    });
    return user.type;
  };

  fetchUserDefaultRates = async () => {
    let user = null;
    [, user] = await to(UserService.get(AuthService.getUserId()));
    let defaultRatesData;
    if (user?.defaultRates?.length > 0) {
      defaultRatesData = await Promise.all(
        user.defaultRates.map(async (defaultRateId) =>
          getDefaultRate(defaultRateId)
        )
      );
    }
    this.setState({ userDefaultRates: defaultRatesData || [] });
  };

  resizeHandler = () => {
    return this.setState({ isMobile: checkWidth() });
  };

  handlePurchase = async (forfeit, numberOfSites = null) => {
    this.setState({ mailSent: true });
    const { addressCity, addressZipCode } = this.state;
    let userId = this.state.uid;
    const token = await ApiService.getToken();
    await Axios.post(
      AuthConstants.URL_ADMIN,
      {
        forfeit: `Licence ${forfeit}`,
        userId,
        numberOfSites,
        city: addressCity,
        postal: addressZipCode,
        region: "",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `${token}`,
        },
      }
    );

    await Axios.post(
      AuthConstants.URL_CUSTOMER,
      {
        forfeit: `Licence ${forfeit}`,
        userId,
        numberOfSites,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `${token}`,
        },
      }
    );
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this.resizeHandler);

  componentDidMount = async () => {
    window.addEventListener("resize", this.resizeHandler);

    if (AuthService.isAuthLocally() || AuthService.getUserId() !== null) {
      const userType = await this.fetchUserType();
      let user = null;
      [, user] = await to(UserService.get(AuthService.getUserId()));

      await UserService.addGridDefaultValues(user);
      await UserService.getGridDefaultValues(user);
      this.setState({ userType });
      this.fetchUserSites();

      this.fetchUserDefaultRates();
      this.fetchNumberOfSites();

      this.setState({
        rendering: "SITES",
      });

      UserService.update(AuthService.getUserId(), {
        _lastConnected: dayjs().format("DD/MM/YYYY HH:mm"),
      });
      if (this.props?.location?.state?.from === "NO_INDEX") {
        // when coming from index error
        this.handleShowEditSite(this.props?.location?.state?.site);
      }
    }
  };
}
export default withRouter(Home);
Home.contextType = LocaleContext;
