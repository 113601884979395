export const fr_notifications = {
  errors: {
    "email-already-in-use": "Cet email est déjà utilisé",
    badFormat: "Veuillez entrer un email valide",
    emailDontMatch: "Les deux emails sont différents",
    missingPassword: "Veuillez entrer votre mot de passe",
    wrongPassword: "Mot de passe incorrect",
  },
  success: {
    savedCredentials: "Vos identifiants ont bien été enregistrés.",
    resetPasswordMailsent:
      "Un mail a été envoyé pour changer votre mot de passe",
  },
};

export const en_notifications = {
  errors: {
    "email-already-in-use": "This email is already in use",
    badFormat: "Please enter a valid email",
    emailDontMatch: "The two mails don't match",
    missingPassword: "Please enter your password",
    wrongPassword: "Wrong password",
  },
  success: {
    savedCredentials: "Your credentials have been saved",
    resetPasswordMailsent: "A mail has been sent to reset your password",
  },
};
