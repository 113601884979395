import styled from "styled-components";

const SiteActionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(0, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin-top: 1%;

  button {
    font-size: 17px;
    padding: 13px 20px;
    height: 80%;
    width: 140%;
    color: white;

    &:disabled {
      background-color: white !important;
      color: black;
    }
  }

  .analyze,
  .scenarios,
  .results {
    width: 100%;
    background-color: #003b58;
    border-color: #003b58;
    color: white;
    /* transition: 0.7s ease 0s; */
    &:hover {
      border-color: #003b58;
      color: #006276;
      background-color: white;
    }
  }

  .duplicate,
  .modify,
  .sup {
    width: 60%;
    background-color: white;
    border-color: #003b58;
    color: #003b58;
    /* transition: 0.7s ease 0s; */
    // justify-content: normal;
    &:hover {
      border-color: #003b58;
      color: #006276;
      background-color: #1c9d76;
    }
  }

  & .analyze {
    grid-area: 1 / 1 / 2 / 2;
  }

  & .scenarios {
    grid-area: 2 / 1 / 3 / 2;
  }

  & .results {
    grid-area: 3 / 1 / 4 / 2;
   
  }

  & .modify {
    width: 100%;
    grid-area: 2 / 3 / 3 / 4;
    &:hover {
      background-color: #003b58;
      color: white;
    }
  }

  & .duplicate {
    width: 100%;
    grid-area: 1 / 3 / 2 / 4;
    &:hover {
      background-color: #003b58;
      color: white;
    }
  }

  & .sup {
    width: 100%;
    grid-area: 3 / 3 / 3 / 4;
    &:hover {
      background-color: red;
      color: white;
    }
    &:disabled {
      color: black;
    }
  }
  @media only screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    button {
      width: 100%;
    }

    .modify,
    .sup {
      justify-content: center;
    }
    & button {
      grid-column: 1 / -1;
    }

    & .results {
      grid-row: 3;
    }
    & .modify {
      grid-row: 4;
    }

    & .sup {
      grid-row: 5;
    }
  }
`;

export default SiteActionsContainer;
